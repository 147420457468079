import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-shareholders-password',
/*   standalone: true,
  imports: [], */
  templateUrl: './shareholders-password.component.html',
  styleUrl: './shareholders-password.component.scss'
})
export class ShareholdersPasswordComponent {

  public dataForm!: FormGroup;
  public alertMessage: string = "";
  public alertStatus: string = "";
  public showAlert: boolean = false;
  
  constructor(private formBuilder: FormBuilder){
    this.dataForm = this.formBuilder.group({
      actualPass: ['', [Validators.required]],
      newPass:['', [Validators.required]],
      reNewPass:['', [Validators.required]]
    })
  }

  submit(){
    const data = {
      actualPass : this.dataForm.get('actualPass')?.value,
      newPass : this.dataForm.get('newPass')?.value,
      reNewPass : this.dataForm.get('reNewPass')?.value,
    }
    this.dataForm.reset()
  }

}
