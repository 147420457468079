<div class="container">
<div class="banner">
  <h1>Junta de <span>Accionistas</span></h1>
</div>
<div *ngIf="showAlert">
  <app-alerts [alertMessage]="alertMessage" [alertStatus]="alertStatus"></app-alerts>
</div>
  <div class="body-password">
    <button class="send-button" routerLink="/junta-de-accionistas"><mat-icon>chevron_left</mat-icon><mat-icon>home</mat-icon></button>
    <div class="password-container">
        <form [formGroup]="dataForm">
          <div class="info-title">
            <mat-icon>key</mat-icon>
            <h2>Cambiar contraseña</h2>
          </div>
          <div class="password-form">
            <mat-form-field appearance="outline">
              <mat-label>Contraseña actual</mat-label>
              <input matInput formControlName="actualPass" type="password"/>
<!--               <mat-error>La contraseña no es correcta</mat-error> -->
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Contraseña nueva</mat-label>
              <input matInput formControlName="newPass" type="password"/>
<!--               <mat-error>Introduzca una contraseña con una longitud de 8 caracteres</mat-error> -->
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Reescriba la contraseña</mat-label>
              <input matInput formControlName="reNewPass" type="password"/>
<!--               <mat-error>La contraseña no coincide con el campo anterior</mat-error> -->
            </mat-form-field>
            <button class="send-button" type="submit" (click)="submit()">Cambiar</button>
          </div>
        </form>
      </div>
</div>
</div>


