<div class="shareholders-data">
    <div class="banner">
        <h1>Junta de <span>Accionistas</span></h1>
    </div>
    <div class="alert_container">
        <app-alerts *ngIf="alert" [alertMessage]="'Archivo subido correctamente'" [alertStatus]="'ok'"></app-alerts>
    </div>
    <app-upload-file *ngIf="isAdmin && restartUploadButton" [fileType]="'test'" [isForm]="true" [shareHolder]="true" (fileUploadedOk)="recieveUploadedOk($event)" (closeAlert)="recieveCloseAlert($event)"></app-upload-file>
    <div class="pass-container">
        <button class="password-btn" routerLink="/junta-de-accionistas/password">Cambiar contraseña</button>
    </div>
    <div class="tableMaterial">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef > Archivo </th>
                <td mat-cell *matCellDef="let element"> {{element.title}}</td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef> Fecha </th>
                <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'dd-MM-yyyy'}} </td>
            </ng-container>
            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef
                ></th>
                <td mat-cell *matCellDef="let element"><mat-icon (click)="downloadDocument(element.id, element.title)">download</mat-icon></td>
            </ng-container>
            <ng-container matColumnDef="second_icon" *ngIf="isAdmin">
                <th mat-header-cell *matHeaderCellDef
                ></th>
                <td mat-cell *matCellDef="let element"><mat-icon (click)="openModal(element.id)" style="color: red">clear</mat-icon></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator class="paginator_container" *ngIf="tablePaginatorInfo" [length]="tablePaginatorInfo.total" [pageSize]="offset" (page)="onPageChange($event)"></mat-paginator>
</div>
